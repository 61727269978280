import Vue from "vue";
import greek from "greek-utils";
import { debounce } from "debounce";
export default (
  { req, res, route, app, store, $axios, $cookies, redirect, env, $clerk },
  inject
) => {
  const diagnostics = (a) => {
    Object.keys(a).forEach((k) =>
      console.info(k, (JSON.stringify(a[k]) || "").length)
    );
  };
  const log = (e) => {
    console.error(e);
  };

  const hyperLog = (type, s) => {
    // eslint-disable-next-line no-console
    if (!type) {
      const logStyle =
        "background: #ff6914;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";
      console.info("%cShopster", logStyle, s);
    } else if (type == "error") {
      const logStyle =
        "background: #ff4455;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";
      console.info("%cError", logStyle, s);
    } else if (type == "warning") {
      const logStyle =
        "background: #555;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";
      console.info("%cWarning", logStyle, s);
    }
  };

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const initSpeech = async () => {
    const annyang = await import("annyang");
    if (annyang) {
      annyang.setLanguage("el-GR");
      const commands = {
        "θέλω (να αγοράσω) *q": (q) => {
          app.router.push({
            path: `/search/+q=${q}`,
          });
          speak();
        },
        "βρες μου *q": (q) => {
          app.router.push({
            path: `/search/+q=${q}`,
          });
          speak();
        },
        "πήγαινε (με) στο *barcode": (barcode) => {
          if (barcode != "καλάθι") {
            app.router.push({
              path: `/item/${barcode.replace(/\ /g, "")}`,
            });
          } else {
            app.router.push({
              path: `/cart`,
            });
          }
        },
        "βάλτο στο καλάθι": () => {
          if (store.getters.item) {
            app.$hyperCart.addToCart(
              store.getters.item,
              app.$hyperCart.getCart()
            );
            speak();
          }
        },
        "(i am) looking for *q": (q) => {
          app.router.push({
            path: `/search/+q=${q}`,
          });
          speak();
        },
        "(i am) searching for *q": (q) => {
          app.router.push({
            path: `/search/+q=${q}`,
          });
          speak();
        },
        πίσω: (q) => {
          window.history.back();
          speak();
        },
        τηλέφωνο: () => {
          location.href = `tel:${store.getters.store.eshop.phone}`;
          speak();
        },
        email: () => {
          location.href = `mailto:${store.getters.store.eshop.email}`;
          speak();
        },
      };
      annyang.addCommands(commands);
      annyang.addCallback("resultMatch", (userSaid) => {
        $nuxt.$emit("closeMenu");
        store.commit("said", userSaid);
      });
      annyang.addCallback("soundstart", (userSaid) => {});
      annyang.addCallback(
        "resultMatch",
        function (userSaid, commandText, phrases) {
          console.warn(userSaid); // sample output: 'hello'
          console.warn(commandText); // sample output: 'hello (there)'
          console.warn(phrases); // sample output: ['hello', 'halo', 'yellow', 'polo', 'hello kitty']
        }
      );
    }
  };

  const speak = async () => {
    const annyang = await import("annyang");
    if (!store.getters.annyang) {
      store.commit("annyang", annyang);
      await initSpeech();
    }
    try {
      if (
        (
          await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
          })
        ).active
      ) {
        if (annyang.isListening()) {
          annyang.abort();
          store.commit("listening", false);
          return;
        }
        annyang.start({
          autoRestart: true,
          continuous: false,
        });
        store.commit("listening", true);
      } else {
        initSpeech();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const paintSvg = (name, selector, replace, color) => {
    if (!store.getters.store.eshop && !color) {
      return;
    }
    var re = new RegExp(replace, "g");
    $axios
      .$get(`${location.protocol}//${location.host}/${name}.svg`)
      .then((res) => {
        let temp = res.replace(
          re,
          color ? color : store.getters.store.eshop.primary
        );
        if (document.querySelector(selector)) {
          document.querySelector(selector).innerHTML = temp;
        }
      });
  };
  const scrollTo = (el) => {
    if (process.browser) {
      let ele;
      const isDOM = (el) => el instanceof Element;
      if (!isDOM(el)) {
        ele =
          document.querySelector(el) ||
          document.querySelector(`[data-scrollto="${el}"]`) ||
          document.querySelector(`[data-shopster_id="${el}"]`);
      }
      if (!ele) {
        return;
      }
      let y = ele.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y - 200,
        behavior: "smooth",
      });
      return "scrolled";
    }
  };
  const scrollTop = () => {
    if (process.browser) {
      return window.scrollTo(0, 0);
    }
    return false;
  };
  const toSafeFixed = (v, l) => {
    try {
      return v.toFixed(l);
    } catch (error) {
      return v;
    }
  };

  const sortIt = (ar, key, order) => {
    let temp = ar.sort(function (a, b) {
      return (a[key] || "").toString().localeCompare(b[key].toString());
    });
    if (order) {
      temp = temp.reverse();
    }
    return temp;
  };

  const safeIncludes = (v, q) => {
    return (v + "" || "").includes(q);
  };

  const greekIfy = (string) => {
    return greek.toGreeklish(
      (string || "").toLowerCase().replace(/[\/&=?%`'"΄ ]/g, "-")
    );
  };

  const scrollHeight = (selector, key) => {
    if (process.browser && document.querySelector(selector)) {
      if (key) {
        document.querySelector(selector).style[key] =
          document.querySelector(selector).scrollHeight + "px";
        return;
      }
      return document.querySelector(selector).scrollHeight + "px";
    }
  };

  const eshopIcon = (key, pool, value, loose, flag) => {
    try{
      if (pool) {
        return store.getters.store.eshop.icons[key];
      }
      let compound = `${flag||''}${key}${value||''}`;
      return store.getters.store.eshop.linkedMedia[compound];
    }catch(e){
      return "";
    }
  }
  const eshopIcon2 = (key, pool, value, loose, flag) => {
    try {
      if (pool) {
        return store.getters.store.eshop.icons[key];
      }
      let type = "";
      let tag = "";
      if (value) {
        if (key.includes("tag.")) {
          type = `tag.`;
          tag = key.replace("tag.", "");
        } else {
          type = key;
        }
      } else {
        type = "custom";
      }
      return store.getters.store.eshop.linkedMedia.find((lm) => {
        if (lm.key == type) {
          let v = loose
            ? lm.value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            : lm.value;
          let vv =
            type == "custom"
              ? key
              : loose
              ? value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              : value;
          if (flag && flag != lm.flag) {
            return false;
          }
          if (tag) {
            return vv == v && tag == lm.tag;
          } else {
            return vv == v;
          }
        }
      }).media;
    } catch (error) {
      return "";
    }
  };
  const sEval = (s, ctx) => {
    let st = store;
    if (!store) {
      st = $nuxt.$store;
    }
    let item = ctx || st.getters.item || {};
    let app = st.getters;
    let cart = st.getters.cart;
    let user = st.getters.user;
    let eshop = st.getters.store.eshop;
    let items = st.getters.items;
    let history = st.getters.history;
    try {
      return eval(s);
    } catch (error) {
      // console.warn(error);
    }
  };

  const reactiveText = (t) => {
    if (store) {
      let v = "";
      let replacer;
      try {
        let tt = (t.match(/{{(.*?)}}/g) || []).map((match) =>
          match.replace(/{{|}}/g, "")
        );
        tt.forEach((p) => {
          v = eval(`store.getters.${p}`);
          t = t.replace(`{{${p}}}`, v);
        });
      } catch (error) {
        console.warn(error);
        replacer = t.match(/\{{(.*?)\}}/)[0];
      }
      if (replacer) {
        t = t.replace(replacer, v);
      }
      return t == "undefined" || t === undefined ? "" : t;
    }
    return "";
  };

  const executioner = (s) => {
    setTimeout(s, 0);
  };

  const vSet = (variable, key, value) => {
    Vue.set(variable, key, value);
  };

  const hrefCheck = (href) => {
    return (
      href.includes("http") ||
      href.includes("mailto:") ||
      href.includes("tel:") ||
      href.includes("ftp") ||
      href.includes("file://") ||
      href[0] == "#"
    );
  };

  const globalFixer = (s) => {
    return encodeURIComponent(s.replace(/\+/g, "______"));
  };
  const urlItemCreator = (item) => {
    let branches = ``;
    if (JSON.parse(store.getters.store.eshop.fullProductPath || "false")) {
      let cr = store.getters.store.eshop.catalogRoute;
      let reformer = (k) => {
        return item[`${k}_slug`]
          ? item[`${k}_slug`]
          : encodeURIComponent(item[`${k}_slug`]||item[k]);
      };
      branches = `/${cr}`;
      if (item.business_activity) {
        branches += `/${reformer("business_activity")}`;
      }
      if (item.family) {
        branches += `/${reformer("family")}`;
      }
      if (item.subfamily) {
        branches += `/${reformer("subfamily")}`;
      }
      if (item.category) {
        branches += `/${reformer("category")}`;
      }
      branches += `/product/`;
    } else {
      branches = `/item/`;
    }
    //FIXME - SLUUUUUUUGS
    if (item.slug && !item.slug.includes("https://")) {
      if (item.slug.includes("https://")) {
        try {
          item.slug = "/" + item.slug.replace(/^http[s]?:\/\/.+?\//, "");
        } catch (e) {}
        return item.slug;
      }
      let sl = item.slug.split("--");
      let barcode = sl.slice(-1);
      if (item.slug.includes("--")) {
        branches += `${sl.slice(0, -1).join("-")}--${barcode}`;
      } else {
        branches += `${sl}--${barcode}`;
      }
    } else {
      let sl = slugifyGreek(item.page_title || item.description)
        .replace(/ /g, "-")
        .replace(/\-+/g, "-");
      branches += `${sl}--${item.barcode}`;
    }
    return branches;
  };

  const urlPathCreator = (obj) => {
    let query = ``;
    if (!obj) {
      obj = store.getters.filters;
    } else {
      if (store.getters.store.eshop.fullProductPath) {
        let tags = Object.keys(obj)
          .filter((k) => k.includes("tag."))
          .reduce((acc, _, index) => {
            acc += `/tag.${slugifyGreek(_.replace("tag.", ""))}.${obj[_].split(
              "_or_"
            )
              .map((s) => slugifyGreek((s || []).split(" | ")[0]))
              .join("_or_")}`;
            return acc;
          }, "");
        let manufacturer = ``;
        if (obj.manufacturer) {
          manufacturer = `/m.${slugifyGreek(obj.manufacturer)}`;
        }
        query =
          manufacturer +
          tags +
          Object.keys(obj)
            .filter(
              (f) =>
                ![
                  "business_activity",
                  "family",
                  "subfamily",
                  "category",
                  "manufacturer",
                ].includes(f)
            )
            .reduce((acc, _, index) => {
              if (!index) {
                acc += `?`;
              }
              if (!_.includes("tag.")) {
                let k = _;
                if (k == "barcode") {
                  k = "barcodes";
                }
                acc += `&${k}=${obj[_]}`;
              }
              return acc;
            }, "");
        Object.keys(obj).forEach((f) => {
          if (
            [
              "business_activity",
              "family",
              "subfamily",
              "category",
              "manufacturer",
            ].includes(f)
          ) {
            obj[`${f}_slug`] = obj[f]
              .split("_or_")
              .map((s) => slugifyGreek(s))
              .join("_or_");
          }
        });
      } else {
        let tags = Object.keys(obj)
          .filter((k) => k.includes("tag."))
          .reduce((acc, _, index) => {
            acc += `${obj[_]}`;
            if (index) {
              acc += "_or_";
            }
            return acc;
          }, "");
        if (tags) {
          tags = `&tags=${tags}`;
        }
        query =
          Object.keys(obj)
            .filter(
              (f) =>
                ![
                  "business_activity",
                  "family",
                  "subfamily",
                  "category",
                ].includes(f)
            )
            .reduce((acc, _, index) => {
              if (index) {
                acc += `?`;
              }
              if (!_.includes("tag.")) {
                acc += `&${_}=${obj[_]}`;
              }
              return acc;
            }, "") + tags;
      }
    }
    let brackets = "";
    if (obj.category) {
      brackets = `/${obj.category_slug || obj.category}`;
    }
    if (obj.subfamily) {
      brackets = `/${obj.subfamily_slug || obj.subfamily}${brackets}`;
    } else if (brackets) {
      brackets = `/-${brackets}`;
    }
    if (obj.family) {
      brackets = `/${obj.family_slug || obj.family}${brackets}`;
    } else if (brackets) {
      brackets = `/-${brackets}`;
    }
    if (obj.business_activity) {
      brackets = `/${
        obj.business_activity_slug || obj.business_activity
      }${brackets}`;
    } else if (brackets) {
      brackets = `/-${brackets}`;
    }
    // brackets = `${
    //   obj.business_activity ? `/${obj.business_activity}` : ""
    // }${obj.family ? `/${obj.family}` : ""}${
    //   obj.subfamily ? `/${obj.subfamily}` : ""
    // }${obj.category ? `/${obj.category}` : ""}`;
    let prefix = `/${store.getters.store.eshop.catalogRoute}`;

    return `${prefix}${brackets}${query}`;
  };
  const urlQueryCreator = (exclude) => {
    return Object.keys(store.getters.filters).reduce((acc, k) => {
      if (
        ![
          "business_activity",
          "family",
          "subfamily",
          "category",
          "total",
          "pages",
          "sorting",
          "q",
        ].includes(k) &&
        k != exclude
      ) {
        acc = acc + `&${k}=${store.getters.filters[k]}`;
      }
      return acc;
    }, "");
  };

  const urlClear = (c) => {
    let filters = JSON.parse(JSON.stringify(store.getters.filters));
    let branches = ``;
    if (filters.category && filters.category != "-") {
      branches = `/${encodeURIComponent(
        filters.category_slug || filters.category
      )}`;
    }
    if (filters.subfamily && filters.subfamily != "-") {
      branches = `/${encodeURIComponent(
        filters.subfamily_slug || filters.subfamily
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "subfamily" &&
      filters.category
    ) {
      branches = `/-${branches}`;
    }
    if (filters.family && filters.family != "-") {
      branches = `/${encodeURIComponent(
        filters.family_slug || filters.family
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "family" &&
      filters.subfamily
    ) {
      branches = `/-${branches}`;
    }
    if (filters.business_activity && filters.business_activity != "-") {
      branches = `/${encodeURIComponent(
        filters.business_activity_slug || filters.business_activity
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "business_activity" &&
      filters.family
    ) {
      branches = `/-${branches}`;
    }
    return `/${store.getters.store.eshop.catalogRoute}${branches}`;
  };
  const urlElasticCreator = (c, value, pagination, debug, slug) => {
    // return urlDynamicCreator(c, value, pagination,debug)
    value += "";
    if (!store.getters.store.eshop.slugReady) {
      slug = value;
    }
    let filters = JSON.parse(JSON.stringify(store.getters.filters));
    if (c) {
      if (
        (slug || []).includes(" | ") &&
        store.getters.store.eshop.tagsMultiple
      ) {
        c = `tag.${slug.split(" | ")[1]}`;
        value = slug.split(" | ")[0];
      }
      let cleanValue = (value || "").toLowerCase().split(" | ")[0];
      let finalValue =
        (slug || "").split(" | ")[0] || (value || "").split(" | ")[0];

      if (
        c.includes("tag.") &&
        !(store.getters.store.eshop || {}).tagsMultiple
      ) {
        if (!filters.tags) {
          filters.tags = "";
        }
        let exists = (filters.tags || "")
          .split("_or_")
          .some((v) => v.toLowerCase() == value.toLowerCase());
        if (exists) {
          filters.tags = (filters.tags || "")
            .split("_or_")
            .filter((v) => v != value)
            .join("_or_");
        } else {
          filters.tags = `${filters.tags}${
            filters.tags.length ? "_or_" : ""
          }${value}`;
        }
      } else {
        let f = [];
        if (filters[c]) {
          try {
            if (c == "sorting") {
              f = filters[c].value.split("_or_");
            } else {
              f = filters[c].split("_or_");
            }
          } catch (error) {}
        }
        if (
          f.map((ff) => ff.toLowerCase()).includes(cleanValue.toLowerCase())
        ) {
          f = f.filter((v) => v.toLowerCase() != cleanValue.toLowerCase());
        } else {
          if (c == "sorting") {
            f = [finalValue];
          } else if (c == "price") {
            f = [finalValue];
          } else {
            f.push(finalValue);
          }
        }
        filters[c] = f.join("_or_");
        try {
          if (store.getters.store.eshop.slugReady) {
            
            filters[c.includes('tag')?c:`${c}_slug`] = f.map((ff) => slugifyGreek(ff)).join("_or_");
          }
        } catch (e) {}
      }
      if (filters[c] == "") {
        delete filters[c];
      }
    }
    let based = [
      "business_activity",
      "family",
      "subfamily",
      "category",
      "manufacturer",
    ];
    let skip = [
      "pages",
      "total",
      "sorting",
      "sort",
      "orderby",
      "order",
      "business_activity_slug",
      "family_slug",
      "subfamily_slug",
      "category_slug",
      "manufacturer_slug",
    ];
    if (filters.limit == store.getters.store.eshop.limit) {
      skip.push("limit");
    }
    if (filters.page == 1) {
      skip.push("page");
    }
    if (!pagination) {
      skip.push("page");
    }
    let branches = ``;
    if (filters.category && filters.category != "-") {
      branches = `/${encodeURIComponent(
        (store.getters.store.eshop.slugReady && filters.category_slug) ||
          filters.category
      )}`;
    }
    if (filters.subfamily && filters.subfamily != "-") {
      branches = `/${encodeURIComponent(
        (store.getters.store.eshop.slugReady && filters.subfamily_slug) ||
          filters.subfamily
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "subfamily" &&
      filters.category
    ) {
      branches = `/-${branches}`;
    }
    if (filters.family && filters.family != "-") {
      branches = `/${encodeURIComponent(
        (store.getters.store.eshop.slugReady && filters.family_slug) ||
          filters.family
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "family" &&
      filters.subfamily
    ) {
      branches = `/-${branches}`;
    }
    if (filters.business_activity && filters.business_activity != "-") {
      branches = `/${encodeURIComponent(
        (store.getters.store.eshop.slugReady &&
          filters.business_activity_slug) ||
          filters.business_activity
      )}${branches}`;
    } else if (
      branches.split("/").length > 1 &&
      c != "business_activity" &&
      filters.family
    ) {
      branches = `/-${branches}`;
    }
    if (filters.manufacturer && filters.manufacturer != "-") {
      branches = `${branches}/m.${encodeURIComponent(
        (store.getters.store.eshop.slugReady && filters.manufacturer_slug) ||
          filters.manufacturer
      )}`;
    }
    let tags = Object.keys(filters)
      .filter((k) => k.includes("tags"))
      .reduce((acc, t) => {
        if (acc) {
          acc += "_or_";
        }
        acc += `${encodeURIComponent(filters[t])}`;
        return acc;
      }, "");
      
    if (tags) {
      tags = "tags=" + tags;
    }
    //tag-anagki.sumplirwmanata-diatrofhs-or-vegan/tag-charaktiristika-ekdiloseis-or-ekdiloseis
    //(^tag-)(.*)(-or-)(.*)(\/)
    let tag = "";
    if (store.getters.store.eshop.tagsMultiple) {
      tag = Object.keys(filters)
        .filter((k) => k.includes("tag."))
        .reduce((acc, t) => {
          // if (acc) {
          //   acc += "_or_";
          // }
          
          acc += `/${t}.${encodeURIComponent(filters[t])}`;
          return acc;
        }, "");
    }
    let tt = `/${
      store.getters.store.eshop.catalogRoute
    }${branches}${tag}?${tags}${Object.keys(filters).reduce((acc, k) => {
      if (![...based, ...skip].includes(k) && filters[k]) {
        if (!k.includes("tag")) {
          acc += `&${encodeURI(k)}=${encodeURIComponent(filters[k])}`;
        }
      }
      if (k == "sorting" && (filters[k] || {})) {
        if (typeof filters.sorting == "object" && filters.sorting.value) {
          acc += (filters.sorting.value + "").replace(/\+/g, "&");
        } else if (typeof filters.sorting == "string") {
          acc += (filters.sorting + "").replace(/\+/g, "&");
        }
      }
      return acc;
    }, "")}`;
    if (debug) {
      return tt;
    }
    tt = tt.replace(/\+/g, "%2B");
    return tt;
  };
  let createKey = (r, b) => {
    if (r.v) {
      let compound = ``;
      if (r.f.business_activity) {
        compound += `${encodeURIComponent(
          slugifyGreek(
            r.f.business_activity,
            !store.getters.store.eshop.slugReady
          )
        )}/`;
      }
      if (r.f.family) {
        compound += `${encodeURIComponent(
          slugifyGreek(r.f.family, !store.getters.store.eshop.slugReady)
        )}/`;
      }
      if (r.f.subfamily) {
        compound += `${encodeURIComponent(
          slugifyGreek(r.f.subfamily, !store.getters.store.eshop.slugReady)
        )}/`;
      }
      if (r.f.category) {
        compound += `${encodeURIComponent(
          slugifyGreek(r.f.category, !store.getters.store.eshop.slugReady)
        )}/`;
      }
      if (r.f.manufacturer) {
        if (store.getters.store.eshop.tagsMultiple) {
          compound += `m.${encodeURIComponent(
            slugifyGreek(r.f.manufacturer, !store.getters.store.eshop.slugReady)
          )}`;
        } else {
          compound += `?manufacturer=${encodeURIComponent(
            slugifyGreek(r.f.manufacturer, !store.getters.store.eshop.slugReady)
          )}`;
        }
      }
      if (r.f.tags) {
        if (store.getters.store.eshop.tagsMultiple) {
          compound += r.f.tags.split("_or_").reduce((acc, _) => {
            let __ = _.split(" | ");
            acc += `tag.${slugifyGreek(
              __[1],
              !store.getters.store.eshop.slugReady
            )}.${encodeURIComponent(
              slugifyGreek(__[0], !store.getters.store.eshop.slugReady)
            )}`;
            return acc;
          }, "");
        } else {
          compound += `?tags=${encodeURIComponent(r.f.tags)}`;
        }
      }
      return `/${store.getters.store.eshop.catalogRoute}/${compound}`;
    }
    let s;
    if (typeof r == "string") {
      try {
        s = {
          key: ((r || "").split(":::") || [])[1],
          value: ((r || "").split(":::") || [])[0],
        };
      } catch (e) {}
    } else {
      s = r;
    }
    if (s.key == "description" && b.type == "items") {
      return `/item/${s.barcode}`;
    } else if (s.key == "business_activity") {
      return `/${store.getters.store.eshop.catalogRoute}/${encodeURIComponent(
        s.value
      )}`;
    } else if (s.key == "family") {
      return `/${store.getters.store.eshop.catalogRoute}/-/${encodeURIComponent(
        s.value
      )}`;
    } else if (s.key == "subfamily") {
      return `/${
        store.getters.store.eshop.catalogRoute
      }/-/-/${encodeURIComponent(s.value)}`;
    } else if (s.key == "category") {
      return `/${
        store.getters.store.eshop.catalogRoute
      }/-/-/-/${encodeURIComponent(s.value)}`;
    } else if (s.key.includes("tag.") || s.key.includes("tags")) {
      if (store.getters.store.eshop.tagsMultiple) {
        return `/${
          store.getters.store.eshop.catalogRoute
        }/tag.${encodeURIComponent(
          slugifyGreek(
            s.key.replace("tag.", ""),
            !store.getters.store.eshop.slugReady
          )
        )}.${encodeURIComponent(
          slugifyGreek(s.value, !store.getters.store.eshop.slugReady)
        )}`;
      } else {
        return `/${
          store.getters.store.eshop.catalogRoute
        }/?tags=${encodeURIComponent(s.value)} | ${encodeURIComponent(
          s.key.replace("tag.", "")
        )}`;
      }
    } else if (s.key == "manufacturer") {
      return `/${
        store.getters.store.eshop.catalogRoute
      }/?manufacturer=${encodeURIComponent(s.value)}`;
    } else if (
      b.type == "suggest" ||
      b.type == "wildcard" ||
      b.type == "multi_match"
    ) {
      s.key = "q";
    }
    return `/${store.getters.store.eshop.catalogRoute}/?${
      s.key
    }=${encodeURIComponent(s.value)}`;
  };

  let urlDynamicCreator = (c, value, pagination, debug) => {
    value += "";
    let filters = JSON.parse(JSON.stringify(store.getters.filters));
    if (c) {
      let cleanValue = value.replace(` | ${c.replace("tag.", "")}`, "");
      if (c.includes("tag.")) {
        if (!filters.tags) {
          filters.tags = "";
        }
        let exists = (filters.tags || "").split("_or_").some((v) => v == value);
        if (exists) {
          filters.tags = (filters.tags || "")
            .split("_or_")
            .filter((v) => v != value)
            .join("_or_");
        } else {
          filters.tags = `${filters.tags}${
            filters.tags.length ? "_or_" : ""
          }${value}`;
        }
      } else {
        let f = [];
        if (filters[c]) {
          try {
            if (c == "sorting") {
              f = filters[c].value.split("_or_");
            } else {
              f = filters[c].split("_or_");
            }
          } catch (error) {}
        }
        if (f.includes(cleanValue)) {
          f = f.filter((v) => v != cleanValue);
        } else {
          if (c == "sorting") {
            f = [cleanValue];
          } else if (c == "price") {
            f = [cleanValue];
          } else {
            f.push(cleanValue);
          }
        }
        filters[c] = f.join("_or_");
      }
    }

    let based = [
      "business_activity",
      "family",
      "subfamily",
      "category",
      "manufacturer",
    ];
    let skip = ["pages", "total", "sorting", "sort", "orderby", "order"];
    if (!pagination) {
      skip.push("page");
    }
    let branches = ``;
    if (filters.category && filters.category != "-") {
      branches = `/ca.${encodeURIComponent(filters.category)}`;
    }
    if (filters.subfamily && filters.subfamily != "-") {
      branches = `/sf.${encodeURIComponent(filters.subfamily)}${branches}`;
    }
    if (filters.family && filters.family != "-") {
      branches = `/fa.${encodeURIComponent(filters.family)}${branches}`;
    }
    if (filters.business_activity && filters.business_activity != "-") {
      branches = `/ba.${encodeURIComponent(
        filters.business_activity
      )}${branches}`;
    }
    if (filters.manufacturer && filters.manufacturer != "-") {
      branches = `/m.${encodeURIComponent(filters.manufacturer)}${branches}`;
    }
    let tags = Object.keys(filters)
      .filter((k) => k.includes("tags"))
      .reduce((acc, t) => {
        if (acc) {
          acc += "_or_";
        }
        acc += `${encodeURIComponent(filters[t])}`;
        return acc;
      }, "");
    if (tags) {
      tags = "tags=" + tags;
    }
    let tt = `/d${branches}?${tags}${Object.keys(filters).reduce((acc, k) => {
      if (![...based, ...skip].includes(k) && filters[k]) {
        if (!k.includes("tag")) {
          acc += `&${encodeURI(k)}=${encodeURIComponent(filters[k])}`;
        }
      }
      if (k == "sorting" && (filters[k] || {})) {
        if (typeof filters.sorting == "object" && filters.sorting.value) {
          acc += (filters.sorting.value + "").replace(/\+/g, "&");
        } else if (typeof filters.sorting == "string") {
          acc += (filters.sorting + "").replace(/\+/g, "&");
        }
      }
      if (k == "page" && !pagination) {
        acc += "&page=1";
      }
      return acc;
    }, "")}`;
    if (debug) {
      return tt;
    }
    return tt.replace(/\+/g, "%2B");
  };
  const dynamicParams = (p) => {
    return ((p || "").split("/") || []).reduce((acc, p) => {
      if (p.startsWith("m.")) {
        acc.manufacturer = p.replace("m.", "");
      } else if (p.startsWith("ba.")) {
        acc.business_activity = p.replace("ba.", "");
      } else if (p.startsWith("fa.")) {
        acc.family = p.replace("fa.", "");
      } else if (p.startsWith("sf.")) {
        acc.subfamily = p.replace("sf.", "");
      } else if (p.startsWith("ca.")) {
        acc.category = p.replace("ca.", "");
      }
      return acc;
    }, {});
  };

  const formatter = (originalValue, parsedValue) => {
    if (!parsedValue) {
      return parsedValue;
    }
    try {
      ((originalValue || "").match(/\{(.*?)\}/) || []).forEach((p) => {
        if (!isNaN(p)) {
          return parseFloat(parsedValue).toFixed(p);
        } else if (p == "round") {
          return Math.round(parsedValue + "");
        } else if (p == "ceil") {
          return Math.ceil(parsedValue + "");
        } else if (p == "floor") {
          return Math.floor(parsedValue + "");
        }
      });
    } catch (error) {
      console.error(error);
    }
    return parsedValue;
  };
  const userAgent = () => {
    try {
      return window.navigator.userAgent;
    } catch (error) {}
  };

  const getLocation = async () => {
    if (navigator.geolocation) {
      let position = await new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      store.commit("location", {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      return { lat: position.coords.latitude, lng: position.coords.longitude };
    } else {
      return false;
    }
  };
  const createMapMarker = (d) => {
    let html = `<div class="flex items-center flex-wrap p-2 w-full">
  <img src="${d.media}" class="w-12 h-12 object-contain ${
      d.media ? "mx-2" : ""
    }" alt="map_icon">
  <div class="flex flex-col px-2 mx-2 border-x flex-1">
      <span class="font-bold pb-1">${d.title}</span>
      ${
        d.fullAddress
          ? `<span class="text-sm font-semibold">${d.fullAddress}</span>`
          : ``
      }
      ${d.options
        .filter((o) => o.key == "info")
        .reduce((acc, _) => {
          acc += `<span class="text-xs">${_.value}</span>`;
          return acc;
        }, "")}
  </div>
  <span class="font-bold text-sm opacity-70 mr-2 ml-2">
      ${d.cost ? `+${d.cost}€` : "ΔΩΡΕΑΝ"}
  </span>

</div>`;
    let m = {
      infoText: html,
      position: { lat: parseFloat(d.lat), lng: parseFloat(d.lng) },
      draggable: false,
      callback: d.callback,
      [d.generated]: d.generated,
    };
    if (d.icon || eshopIcon("map_pin", 1)) {
      m.icon = {
        scaledSize: { height: 48, width: 48 },
        anchor: { x: 0, y: 0 },
        url: d.icon || eshopIcon("map_pin", 1),
      };
    }
    return m;
  };

  const getStoreShipping = (s) => {
    try {
      return (
        store.getters.store.shippings.find(
          (ss) => ss._id == s._id || s.title == ss.title
        ) || {}
      );
    } catch (error) {
      return {};
    }
  };
  const getStorePayment = (p) => {
    try {
      return store.getters.store.payments.find((pp) => pp._id == p._id) || {};
    } catch (error) {
      return {};
    }
  };
  const equalize = (s, lower, greeklish) => {
    s = s
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      [lower ? "toLowerCase" : "toUpperCase"]();
    if (greeklish) {
      s = slugifyGreek(s);
    }
    return s;
  };
  const safeReplace = (s, r, w) => {
    if (!s) {
      return s;
    }
    return s.replace(new RegExp(r, "g"), w);
  };
  const timeTo = (target, humanize, withDays) => {
    let now = Date.now();
    let diff = target - now;
    let days = Math.floor(diff / 1000 / 60 / 60 / 24);
    let hours = Math.floor(diff / 1000 / 60 / 60) % 24;
    let minutes = Math.floor(diff / 1000 / 60) % 60;
    let seconds = Math.floor(diff / 1000) % 60;
    if (humanize) {
      let tt = { days, hours, minutes, seconds };
      if (!withDays && tt.days) {
        return false;
      }
      if (tt.days) {
        tt.days = Math.abs(tt.days);
      }
      if (tt.hours) {
        tt.hours = Math.abs(tt.hours);
      }
      if (tt.minutes) {
        tt.minutes = Math.abs(tt.minutes);
      }
      if (tt.seconds) {
        tt.seconds = Math.abs(tt.seconds);
      }

      // tt.hours = tt.hours < 10 ? `0${tt.hours}` : tt.hours;
      // tt.minutes = tt.minutes < 10 ? `0${tt.minutes}` : tt.minutes;
      // tt.seconds = tt.seconds < 10 ? `0${tt.seconds}` : tt.seconds;
      return tt;
    }
    return { days, hours, minutes, seconds };
  };

  const slugifyGreek = (text, bypass) => {
    if (bypass) {
      return text;
    }
    const GREEK_TO_LATIN_MAP = {
      Α: "A",
      Β: "V",
      Γ: "G",
      Δ: "D",
      Ε: "E",
      Ζ: "Z",
      Η: "I",
      Θ: "Th",
      Ι: "I",
      Κ: "K",
      Λ: "L",
      Μ: "M",
      Ν: "N",
      Ξ: "X",
      Ο: "O",
      Π: "P",
      Ρ: "R",
      Σ: "S",
      Τ: "T",
      Υ: "Y",
      Φ: "F",
      Χ: "Ch",
      Ψ: "Ps",
      Ω: "O",
      ά: "a",
      έ: "e",
      ή: "i",
      ί: "i",
      ό: "o",
      ύ: "y",
      ώ: "o",
      α: "a",
      β: "v",
      γ: "g",
      δ: "d",
      ε: "e",
      ζ: "z",
      η: "i",
      θ: "th",
      ι: "i",
      κ: "k",
      λ: "l",
      μ: "m",
      ν: "n",
      ξ: "x",
      ο: "o",
      π: "p",
      ρ: "r",
      ς: "s",
      σ: "s",
      τ: "t",
      υ: "y",
      φ: "f",
      χ: "ch",
      ψ: "ps",
      ω: "o",
      ϊ: "i",
      ΐ: "i",
      ϋ: "y",
      ΰ: "y",
      ς: "s",
    };
    // Convert to lowercase
    text = text.toLowerCase();

    // Replace Greek characters with their corresponding Latin characters
    text = text
      .split("")
      .map((c) => GREEK_TO_LATIN_MAP[c] || c)
      .join("");
    // Replace non-Latin or non-Greek characters with '-'
    text = text.replace(/[^\w\s-]/g, "-");

    // Replace spaces and underscores with '-'
    text = text.replace(/[\s_]+/g, "-");

    // Replace more than one '-' with only one
    text = text.replace(/-+/g, "-");

    return text.replace(/^-|-$/g, "");
  };

  const actualSize = (v, type = "", uid, product) => {
    if (process.browser && uid) {
      try {
        let el = document.querySelector(
          `[data-uid="${uid}"]${product ? " img" : ""}`
        );
        type = type + "";
        if (el) {
          let wv = el[`client${type.charAt(0).toUpperCase() + type.slice(1)}`];
          if ((v + "").includes("%")) {
            v = v.replace("%", "");
            return (wv * parseFloat(v)) / 100;
          } else {
            return v;
          }
        }
      } catch (e) {
        return v;
      }
    }
  };
  const acceptedParams = (query) => {
    let accepted = [
      "business_activity",
      "family",
      "subfamily",
      "manufacturer",
      "category",
      "sku",
      "barcodes",
      "barcode",
      "price",
      "prices",
      "stock",
      "manufacturer",
      "page",
      "pages",
      "limit",
      "order",
      "orderby",
      "sorting",
      "perpage",
      "populate",
      "fields",
      "person",
      "persons",
      "tag",
      "tags",
      "q",
      "fullProductPath",
      "catalogRoute",
      "store_id",
      "settings",
      "aggs",
      "size",
      "from",
      "total",
    ];
    return Object.keys(query).reduce((acc, _) => {
      if (accepted.includes(_) || _.includes("tag") || _.includes("tags")) {
        acc[_] = query[_];
      }
      return acc;
    }, {});
  };
  const handleAutocomplete = (place, emit) => {
    if (place.geometry) {
      place.address = place.address_components.reduce((acc, _) => {
        if (_.types[0] == "country") {
          acc[_.types[0]] = _.short_name;
        } else {
          acc[_.types[0]] = _.long_name;
        }
        return acc;
      }, {});
      place.address.lat = place.geometry.location.lat();
      place.address.lng = place.geometry.location.lng();
      if (!place.address.route) {
        place.address.route = place.address.locality;
      }
      let obj = {
        address: place.address.route,
        address_no: place.address.street_number,
        country: place.address.country,
        postal_code: (place.address.postal_code || "").replace(/\ /g, "") || "",
        town: place.address.locality,
        lat: place.address.lat,
        lng: place.address.lng,
      };
      if (emit) {
        $nuxt.$emit(emit, obj);
      }
      return obj;
    }
  };
  inject("hyperUtility", {
    diagnostics,
    log,
    hyperLog,
    debounce,
    mobileCheck,
    initSpeech,
    speak,
    paintSvg,
    scrollTo,
    scrollTop,
    toSafeFixed,
    sortIt,
    safeIncludes,
    greekIfy,
    slugifyGreek,
    scrollHeight,
    eshopIcon,
    sEval,
    reactiveText,
    executioner,
    vSet,
    hrefCheck,
    globalFixer,
    createKey,
    urlItemCreator,
    urlPathCreator,
    urlQueryCreator,
    urlElasticCreator,
    urlDynamicCreator,
    urlClear,
    dynamicParams,
    formatter,
    userAgent,
    getLocation,
    createMapMarker,
    getStoreShipping,
    getStorePayment,
    equalize,
    safeReplace,
    timeTo,
    actualSize,
    acceptedParams,
    handleAutocomplete,
  });
};
